import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Spinner from 'components/common/spinner';
import Collapsible from 'components/common/collapsible';
import SubjectMini from 'components/common/course/subject-mini';
import { Heading } from 'components/common/typography';

import RoutesRegistry from 'services/router/registry';

import { UserAPI } from 'api';

import './styles.less';

const Stage = (props: any) => {
  const { stage, courseId, history } = props;

  const subjects = stage.subjects.map((subs: any) => (
    <SubjectMini
      key={subs.id}
      subject={subs}
      actionBtn={{
        label: 'Explore',
        goTo: (subjectId: number) =>
          history.push(
            `${RoutesRegistry.Courses.path}/${courseId}/subjects/${subjectId}`,
          ),
      }}
    />
  ));

  return (
    <div className="my-courses__stage">
      <Heading type={4}>
        {stage.title}
      </Heading>
      {subjects}
    </div>
  );
};

const Courses = (props: any) => {
  const { courses, history } = props;

  const items = courses.map((item: any) => {
    const stages = item.stages.map((subs: any) => (
      <Stage stage={subs} history={history} courseId={item.course_id} />
    ));

    return {
      title: item.course_title,
      body: stages,
    };
  });

  return <Collapsible content={items} />;
};

const MyCourses = () => {
  const [courses, setCourses] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getMyCourses = async () => {
      try {
        const res = await UserAPI.getMyCourses();
        setCourses(res.content.purchased_items);
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getMyCourses();
  }, []);

  if (busy) {
    return <Spinner />;
  }

  if (!courses) {
    return <>Looks like you don't have any courses yet.</>;
  }


  return(
    <>
      <Courses courses={courses.filter((course: any)=> course.archived == false)} history={history}/>
      <br />
      <br />
      <div className="heading ">
        <h4>Archived Courses</h4>
      </div>
      <Courses courses={courses.filter((course: any)=> course.archived == true)} history={history}/>
    </>
  )
};

export default MyCourses;
