import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import * as Form from 'components/common/form';
import { Heading } from 'components/common/typography';

import { PurchaseAPI } from 'api';

import RoutesGenerator, { Route } from 'services/router/generator';

import BankDetails from '../bank-details';

import './styles.less';
import translate from 'i18n/translate';

const { Button } = Form;

interface CompletePurchaseProps {
  bankDetails: any;
  options: any;
}

const CompletePurchase = (props: CompletePurchaseProps) => {
  const history = useHistory();
  const { bankDetails, options } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      await PurchaseAPI.purchaseItem({
        ...options,
        refNumber: bankDetails[0].reference_number,
      });
      setIsSubmitting(false);
      history.push(
        RoutesGenerator(Route.Dashboard),
      );
    } catch (error) {
      setIsSubmitting(true);
      console.error(error);
    }
  };

  return (
    <div className="complete-purchase">
      <Row gutter={24} type="flex">
        <Col md={24}>
          <Heading type={3}>{translate('bankPayment.completePurchase.heading')}</Heading>
          <p>{translate('bankPayment.completePurchase.step1')}</p>
          <BankDetails bankDetails={bankDetails} />
          <p>{translate('bankPayment.completePurchase.step2')}</p>
        </Col>
        <Col md={24}>
          <Button size="large" className="next-btn" onClick={handleClick} loading={isSubmitting}>
          {translate('bankPayment.completePurchase.completePurchase')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CompletePurchase;
