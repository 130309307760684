import translationEnglish from "./locales/en/translations.json"
import translationSinhala from "./locales/lk/translations.json"

const translate = (componentPath: string) => {
  const keys = componentPath.split('.');
  const language = localStorage.getItem('defaultLang')

  if(language && language == 'en'){
    return keys.reduce((accumulator: any, key: string) => {
      if (accumulator && key in accumulator) {
          return accumulator[key];
      }
  
      return undefined;
    }, translationEnglish);
  }
  else{
    return keys.reduce((accumulator: any, key: string) => {
      if (accumulator && key in accumulator) {
          return accumulator[key];
      }
  
      return undefined;
    }, translationSinhala);
  }

}

export default translate;