import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Icon, Menu, Button } from 'antd';
import _ from 'lodash';

import Spinner from 'components/common/spinner';
import Collapsible from 'components/common/collapsible';
import { Heading } from 'components/common/typography';

import RoutesGenerator, { Route } from 'services/router/generator';

import NumberWCommas from 'utils/number-w-commas';
import constructRedirectURL from 'utils/redirect-url';

import { UserAPI } from 'api';

import './styles.less';
import { title } from 'process';

const StatusLabel = (props: any) => {
  const { status } = props;

  if (status === 'Waiting for Approval') {
    return <div className="installment__status waiting">{status}</div>;
  } else if (status === 'Approved') {
    return <div className="installment__status approved">{status}</div>;
  }

  return null;
};

const MyOrders = () => {
  const [orders, setOrders] = useState<any>(null);
  const [archivedOrders, setarchivedOrders] = useState<any>(null);
  const [busy, setBusy] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getMyCourses = async () => {
      try {
        const res = await UserAPI.getMyOrders();
        setOrders(res.content.orders);
        setarchivedOrders(res.content.archived_orders)
        setBusy(false);
      } catch (error) {
        setBusy(false);
        console.log(error);
      }
    };

    getMyCourses();
  }, []);

  const Installments = (props: any) => {
    const { course } = props;

    const handleClick = (e: any, { type, id, examBatchId, installment }: any) => {
      if (e.key === 'card') {
        const url = constructRedirectURL({
          type,
          id,
        }, {
          reference_number: installment.reference_number,
          installment_plan_id: installment.id,
          stage_or_subject_id: id,
          stage_or_subject: type,
          exam_badge_id: examBatchId,
          exam_badge_name: installment.examBatchName,
          purchase_lan: installment.language,
        });
        window.location.href = url;
      } else {
        history.push(
          `${RoutesGenerator(Route.BankPayment, { type, id })}?ref=${installment.reference_number}&language=${installment.language}`,
        );
      }
    };

    const ItemInstallments = ({ item }: any) => (
      item.installments.map((installment: any) => {
        const menu = (
          <Menu onClick={(e) => handleClick(e, {
            type: item.type,
            id: item.id,
            examBatchId: item.examBatchId,
            examBatchName: item.examBatchName,
            installment,
          })}
          >
            <Menu.Item key="card">Credit / Debit Card</Menu.Item>
            <Menu.Item key="bank">Bank Deposit</Menu.Item>
          </Menu>
        );

        return (
          <div className="installment__item">
            <div>
              <Heading type={4}>{installment.installment_number}</Heading>
              <p className="installment__ref">Reference Code: <span>{installment.reference_number}</span></p>
              <StatusLabel status={installment.status} />
              {
                checkBatchIsExpired(item.examBatchEndDate) && <div className="installment__status waiting">Batch Expired!</div>
              }
              {
                item.order_cancel && <div className="installment__status waiting">Order Cancelled!</div>
              }
            </div>
            <div>
              <p className="installment__price">Rs. {NumberWCommas(installment.price)}</p>

              <Dropdown overlay={menu} disabled={!installment.enabled || checkBatchIsExpired(item.examBatchEndDate) || item.order_cancel }>
                <Button>
                  Make Payment <Icon type="down" />
                </Button>
              </Dropdown>
            </div>
          </div>
        );
      })
    );

    return course.items.map((item: any) => (
      <div className="items-list">
        <div className="items-list__header">

          {/* <Heading type={4}>
          { item.stage ?
            `${item.stage.name}: ${item.title}` : item.title + `${item.examBatchName}` + `${item.language}`
          }
          </Heading> */}


          { item.examBatchName && item.title &&
          <div style={{
            display: 'flex',
            justifyContent: 'space-between'

          }}>
            <Heading type={4}>{`${item.title}`}</Heading>
            <Heading type={4}>
              {`${item.examBatchName}`+ ', '+ `${item.installments[0].language == 'en' ? 'English' : 'Sinhala'}`}
            </Heading>
          </div>
          }

        </div>
        <ItemInstallments item={item} />
      </div>
    ));
  };

  /**
   * Sort, filter and construct orders
   *
   * @param ordersList Orders list
   * @returns Constructed orders
   */
  const constructOrders = (ordersList: any) => {
    const constructedOrders: any = [];

    ordersList.forEach((order: any) => {
      const existingIndex = _.findIndex(constructedOrders, { id: order.course.id });
      if (existingIndex === -1) {
        // Base case
        constructedOrders.push({
          id: order.course.id,
          title: order.course.title,
          items: [
            {
              id: order.stage_or_subject_id,
              type: order.stage_or_subject,
              examBatchId: order.exam_badge.id,
              examBatchName: order.exam_badge.name,
              examBatchEndDate: order.exam_badge.end_date,
              stage: order.stage,
              title: order.title,
              language: order.language,
              installments: order.installments,
              order_cancel: order.order_cancel,
            },
          ],
        });
      } else {
        const existingCourse = constructedOrders[existingIndex];
        const existingItem = _.findIndex(existingCourse.items, {
          id: order.stage_or_subject_id,
          type: order.stage_or_subject,
        });

        // if (existingItem === -1) {
          existingCourse.items.push({
            id: order.stage_or_subject_id,
            type: order.stage_or_subject,
            examBatchId: order.exam_badge.id,
            examBatchName: order.exam_badge.name,
            examBatchEndDate: order.exam_badge.end_date,
            stage: order.stage,
            title: order.title,
            language: order.language,
            installments: order.installments,
            order_cancel: order.order_cancel,
          });
        // } else {
        //   existingCourse.items[existingItem].installments.push(order.installments);
        // }
      }
    });

    return constructedOrders;
  };

  const checkBatchIsExpired = (end_date: string) => {
    let difference_In_Time = 0;
    let expired = false;

    const today = new Date(Date.now());
    const expDate = new Date(end_date + "T00:00");
    const dayMilisecons = 1000 * 60 * 60 * 24;

    difference_In_Time = expDate.getTime() - today.getTime() - dayMilisecons;

    if (difference_In_Time > 0) {
      expired = false;
    } else {
      expired = true;
    }
    return expired;
  };

  const Orders = () => {
    const items = constructOrders(orders).map((order: any) => (
      {
        title: `${order.title}`,
        body: <Installments course={order} />,
      }
    ));

    return <Collapsible content={items} className="installment" />;
  };

  const ArchivedOrders = () => {
    const items = constructOrders(archivedOrders).map((order: any) => (
      {
        title: `${order.title}`,
        body: <Installments course={order} />,
      }
    ));

    return <Collapsible content={items} className="installment" />;
  };

  if (busy) {
    return <Spinner />;
  }

  if ((!orders || orders.length < 1) && (!archivedOrders || archivedOrders.length < 1)) {
    return <>There are no pending orders.</>;
  }

  return (
    <>
      <Orders />
      <br></br>
      <br></br>
      <Heading type={4} >Archived Orders</Heading>
      <ArchivedOrders />
    </>
  );
};

export default MyOrders;
